import { useContext } from 'react'
import { styled } from 'linaria/react'
import { Logo } from 'components/Logo'
import { AppContext } from 'contexts/AppContext'
import { Screen } from 'components/Screen'
import useTimeout from 'hooks/useTimeout'
import theme from 'theme/theme'
import { ReactComponent as Mobile } from 'theme/icons/mobile.svg'

const ScreenS = styled(Screen)`
  height: 100%;
  background-color: ${theme.colors.introBackground};
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  color: ${theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 3;
  margin: -4vh -4vw;
`

export const MobileHelp = () => {
  const { setScreen } = useContext(AppContext)

  useTimeout(() => setScreen('Intro'), 4000)

  return (
    <ScreenS>
      <Logo />
      <Content onClick={() => setScreen('Intro')}>
        <div>
          <Mobile style={{ maxHeight: 150, marginBottom: '4vh' }} />
          <div>
            Tilt your device
            <br /> to play
          </div>
        </div>
      </Content>
    </ScreenS>
  )
}
