import { useContext } from 'react'
import { styled } from 'linaria/react'
import { AppContext } from 'contexts/AppContext'
import theme from 'theme/theme'
import bannerTop from './banner-top.png'
import bannerBottom from './banner-bottom.png'

const Wrapper = styled.div`
  text-align: center;
  position: relative;
  width: 100%;
  max-width: 380px;
  height: 100%;
  overflow: hidden;

  margin-top: 20px;

  @media only screen and (min-height: 768px) {
    margin-top: 10vh;
  }
`

const BannerTop = styled.img`
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  z-index: -1;
`

const BannerBottom = styled.img`
  width: calc(100% - 14px);
  position: absolute;
  left: 6px;
  right: 0;
  z-index: -2;
  animation: slideDown 1s linear forwards;

  @keyframes slideDown {
    0% {
      margin-top: -85%;
    }
    100% {
      margin-top: 15%;
    }
  }
`

const TextWrapper = styled.div`
  opacity: 0;
  animation: fadeIn 0.5s 1s forwards;

  @media only screen and (max-height: 485px), screen and (max-width: 390px) {
    font-size: 4.25vw;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

const Title = styled.h2`
  color: ${theme.colors.gold};
  margin: 25% 0 0;
  font-weight: normal;
  line-height: 1;
`

const ScoreS = styled.div`
  color: ${theme.colors.white};
  font-size: 100px;
  line-height: 1.2;

  @media only screen and (max-height: 485px), screen and (max-width: 390px) {
    font-size: 80px;
  }
`

const BottomText = styled.div`
  color: ${theme.colors.white};
  line-height: 1.2;
`

export const GameOverBanner = () => {
  const { score } = useContext(AppContext)

  return (
    <Wrapper>
      <BannerBottom src={bannerBottom} alt="" />
      <BannerTop src={bannerTop} alt="" />
      <TextWrapper>
        <Title>
          Congrats!
          <br /> With this wool
          <br /> You have produced:
        </Title>
        <ScoreS>{score}</ScoreS>
        <BottomText>
          Meters of
          <br /> 21 Micron Fabric
        </BottomText>
      </TextWrapper>
    </Wrapper>
  )
}
