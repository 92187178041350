const theme = {
  colors: {
    white: '#ffffff',
    gold: '#b99967',
    introBackground: '#76afbc',
    gameBackground1: '#fccd49',
    gameBackground2: '#1d2347',
    gameOverBackground: '#76afbc',
    buttonBackground: '#786e53',
    buttonBackgroundHover: '#7f4114',
  },
}

export default theme
