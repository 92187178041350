import React, { useCallback, useContext, useEffect, useState } from 'react'
import { styled } from 'linaria/react'
import { AppContext } from 'contexts/AppContext'
import useAnimationFrame from 'hooks/useAnimationFrame'
import randomInt from 'utils/randomInt'
import pxToPercentage from 'utils/pxToPercentage'
import randomNumber from 'utils/randomNumber'
import Wool1 from './wool1.png'
import Wool2 from './wool2.png'

const WoolS = styled.div`
  position: absolute;
  z-index: 1;
`

const Wool = React.forwardRef((_props, ref) => {
  const woolRef = React.useRef(null)
  const { setScreen, score } = useContext(AppContext)
  const [height] = useState(() => randomInt(11, 15))
  const [image] = useState(() => randomInt(0, 1))
  const [styles] = useState(() => {
    // 0 = wool1 = 240 x 249 = 0.964
    // 1 = wool2 = 291 x 258 = 1.128
    const width = height * (image === 0 ? 0.964 : 1.128)
    return {
      wrapper: {
        top: `${-height}vw`,
        left: `calc(${randomInt(
          width,
          pxToPercentage(window.innerWidth)
        )}% - ${width}vw)`,
        transform: `rotateZ(${randomInt(0, 360)}deg)`,
      },
      img: {
        width: `calc(40px + ${width}vw)`,
        height: `calc(40px + ${height}vw)`,
      },
    }
  })

  // Speed starts from 90 px/s and grows by 0.04% of current score (up to 1000), randomized +-10%
  const [speed] = useState(() => {
    const speed = Math.min(0.09 + score * 0.004, 1)
    const randomized = speed + randomNumber(-(speed / 10), 0)
    return randomized
  })

  useEffect(() => {
    ref(woolRef.current)
    // console.debug(`Wool ${id}. Speed ${speed}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref])

  // Move wool
  const loop = useCallback(
    ({ time }) => {
      // Stop animation if the wool was destroyed
      if (!woolRef.current) return true
      const top = parseInt(window.getComputedStyle(woolRef.current).top)
      // Check if slipped past bottom of screen
      if (top >= window.innerHeight) setScreen('GameOver')
      // time is in ms, so speed * delta is the pixel delta for this frame
      else woolRef.current.style.top = `calc(${-height}vw + ${time * speed}px)`
    },
    [height, setScreen, speed]
  )
  useAnimationFrame(loop)

  return (
    <WoolS ref={woolRef} style={styles.wrapper}>
      <img src={image === 0 ? Wool1 : Wool2} style={styles.img} alt="" />
    </WoolS>
  )
})

export default Wool
