import React from 'react'

const AppContext = React.createContext({
  isMobile: undefined,
  audio: false,
  setAudio: audio => {},
  screen: 'Intro',
  setScreen: screen => {},
  score: 0,
  setScore: score => {},
})
AppContext.displayName = 'Screen'

export { AppContext }
