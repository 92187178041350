import { styled } from 'linaria/react'
import { ReactComponent as SheepSvg } from './sheep.svg'
import { ReactComponent as BeforeComb } from './before-comb.svg'
import { ReactComponent as Comb } from './comb.svg'
import { ReactComponent as Dryer } from './dryer.svg'
import { ReactComponent as ScissorsA } from './scissors-a.svg'
import { ReactComponent as ScissorsB } from './scissors-b.svg'
import { ReactComponent as Wool } from '../Wool/wool.svg'

const Wrapper = styled.div`
  position: relative;
  max-width: 343px;

  @media only screen and (min-width: 481px) {
    margin: 0;
  }
`

const SheepS = styled(SheepSvg)`
  width: 100%;
  max-height: 100%;
  display: blocK;
`

const BeforeCombS = styled(BeforeComb)`
  position: absolute;
  width: 8.7%;
  top: 48.5%;
  left: 58.3%;
`

const CombS = styled(Comb)`
  position: absolute;
  width: 33%;
  top: 46%;
  left: 63%;
  animation: comb linear 3.8s infinite;

  @keyframes comb {
    25% {
      transform: translate(-10px, -5px) rotate(-5deg);
    }
    35% {
      transform: translate(-10px, -20px) rotate(10deg);
    }
    55% {
      transform: translate(10px, -10px) rotate(0);
    }
    65% {
      transform: translate(0, -10px) rotate(0);
    }
    80% {
      transform: translate(-10px, -5px) rotate(-10deg);
    }
  }
`

const DryerS = styled(Dryer)`
  position: absolute;
  width: 33.5%;
  top: 25%;
  left: 0;
  animation: dryer linear 3s infinite;

  @keyframes dryer {
    25% {
      transform: translate(-20px, -10px) rotate(-7.5deg);
    }
    50% {
      transform: translate(-25px, 40px) rotate(-15deg);
    }
    75% {
      transform: translate(5px, -5px) rotate(5deg);
    }
  }
`

const Scissors = styled.div`
  position: absolute;
  top: 19%;
  left: 63%;
  animation: scissors linear 2s infinite;
  transform-origin: 34px 57px;

  @keyframes scissors {
    15% {
      transform: translate(0px, 15px) rotate(5deg);
    }
    30% {
      transform: translate(0, -5px) rotate(0);
    }
    50% {
      transform: translate(-5px, 10px) rotate(10deg);
    }
    75% {
      transform: translate(5px, 0) rotate(5deg);
    }
  }
`

const ScissorsAS = styled(ScissorsA)`
  position: absolute;
  width: 32px;
  top: 0;
  left: 15px;
  animation: scissors-b ease-in-out 1.5s infinite;
  transform-origin: 55% 51%;

  @keyframes scissors-b {
    0%,
    33%,
    100% {
      transform: rotate(8deg);
    }
    66% {
      transform: rotate(-20deg);
    }
  }
`

const ScissorsBS = styled(ScissorsB)`
  position: absolute;
  width: 74px;
  top: 22px;
  left: 0;
`

const Wool1S = styled(Wool)`
  position: absolute;
  width: 40px;
  top: 160px;
  left: 190px;
  animation: wool1 ease-in-out 2.5s 1s infinite;
  transform-origin: 75px 150px;

  @keyframes wool1 {
    25% {
      opacity: 1;
    }
    50%,
    100% {
      transform: rotate(120deg);
      opacity: 0;
    }
  }
`

const Wool2S = styled(Wool)`
  position: absolute;
  width: 55px;
  top: 240px;
  left: 90px;
  animation: wool2 linear 3.5s infinite;

  @keyframes wool2 {
    0% {
      opacity: 0;
    }

    10% {
      transform: translate(0, 0);
    }

    20% {
      opacity: 1;
      transform: translate(-15px, 5px);
      animation-timing-function: ease-out;
    }

    35% {
      opacity: 1;
    }

    50%,
    100% {
      transform: rotate(120deg) translate(150px, 15px);
      opacity: 0;
    }
  }
`

const Wool3S = styled(Wool)`
  position: absolute;
  width: 40px;
  top: 165px;
  left: 152px;
  animation: wool3 linear 4s 0.5s infinite;

  @keyframes wool3 {
    25% {
      transform: translate(-60px, -100px);
      opacity: 1;
    }

    50%,
    100% {
      animation-timing-function: ease-in;
      transform: translate(-100px, -250px);
      opacity: 0;
    }
  }
`

const Wool4S = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background-color: #fff;
  position: absolute;
  top: 185px;
  left: 155px;

  animation: wool4 linear 4s 0.5s infinite;
  transform-origin: -300px 600px;

  @keyframes wool4 {
    20% {
      opacity: 1;
    }
    40%,
    100% {
      transform: rotate(-25deg);
      opacity: 0;
    }
  }
`

const Wool5S = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background-color: #fff;
  position: absolute;
  top: 155px;
  left: 210px;

  animation: wool5 linear 5s 1s infinite;
  transform-origin: -300px -500px;

  @keyframes wool5 {
    20% {
      opacity: 1;
    }
    40%,
    100% {
      transform: rotate(-20deg);
      opacity: 0;
    }
  }
`

const Wool6S = styled.div`
  position: absolute;
  top: 160px;
  left: 190px;
  animation: wool6 linear 3s 2s infinite;

  @keyframes wool6 {
    20% {
      opacity: 1;
    }
    40%,
    100% {
      transform: translate(150px, 120px) rotate(360deg);
      opacity: 0;
    }
  }
`

const Wool6a = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: #fff;
  margin-bottom: -20px;
`
const Wool6b = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: #fff;
`

const Wool6 = () => (
  <Wool6S>
    <Wool6a />
    <Wool6b />
  </Wool6S>
)

export const Sheep = () => {
  return (
    <Wrapper>
      <SheepS />
      <Wool5S />
      <Scissors>
        <ScissorsBS />
        <ScissorsAS />
      </Scissors>
      <Wool1S />
      <CombS />
      <Wool6 />
      <BeforeCombS />
      <Wool3S />
      <DryerS />
      <Wool2S />
      <Wool4S />
    </Wrapper>
  )
}
