import { useContext } from 'react'
import { AppContext } from 'contexts/AppContext'

export const Score = ({ className, style }) => {
  const { score } = useContext(AppContext)
  return (
    <span className={className} style={style}>
      {score.toString().padStart(3, '0')}
    </span>
  )
}
