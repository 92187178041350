import { useContext } from 'react'
import { styled } from 'linaria/react'
import { Icon } from 'components/Icon'
import { AppContext } from 'contexts/AppContext'
import { ReactComponent as Sound } from 'theme/icons/sound.svg'
import { ReactComponent as SoundOff } from 'theme/icons/soundOff.svg'

const ButtonS = styled.button`
  position: absolute;
  top: 4vh;
  left: 4vw;
  cursor: pointer;
  z-index: 5;
`

export const SoundButton = props => {
  const { audio, setAudio } = useContext(AppContext)
  return (
    <ButtonS
      onClick={() => setAudio(!audio)}
      title={audio ? 'Turn off sound' : 'Turn on sound'}
      {...props}
    >
      <Icon icon={audio ? <Sound /> : <SoundOff />} size="small" />
    </ButtonS>
  )
}
