import { styled } from 'linaria/react'
import theme from 'theme/theme'

export const Button = styled.button`
  cursor: pointer;
  text-transform: uppercase;
  color: #fff;
  background-color: ${theme.colors.buttonBackground};
  border-radius: 2px;
  padding: 0.75em;
  font-size: 20px;

  &:hover {
    background-color: ${theme.colors.buttonBackgroundHover};
  }

  &[disabled] {
    cursor: auto;
  }
`
