import { useContext, useEffect } from 'react'
import { styled } from 'linaria/react'
import useSound from 'use-sound'
import { Button } from 'components/Button'
import { Logo } from 'components/Logo'
import { Screen } from 'components/Screen'
import { Sheep } from 'components/Sheep/Sheep'
import { AppContext } from 'contexts/AppContext'
import theme from 'theme/theme'
import { ReactComponent as Keyboard } from 'theme/icons/keyboard.svg'
import dryerSound from 'sounds/dryer.m4a'
import scissorsSound from 'sounds/scissors.m4a'

const ScreenS = styled(Screen)`
  flex: 0 0 50%;
  height: 50%;
`

const Content = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`

const Wrapper = styled.div`
  width: 100%;
  max-width: 800px;
  display: flex;
  align-items: flex-end;
  height: 59vh;
`

const Help = styled.div`
  display: none;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10vh;

  @media only screen and (min-width: 676px) {
    display: flex;
  }
`

const HelpText = styled.div`
  text-align: center;
  color: ${theme.colors.white};
`

const KeyboardS = styled(Keyboard)`
  width: 148px;
  margin-bottom: 2em;
`

const SheepWrapper = styled.div`
  flex: 1;
  justify-content: center;
  display: flex;
  max-height: 115%;
`

const Footer = styled.div`
  padding-top: 2vh;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100px;

  @media only screen and (max-height: 485px) {
    min-height: 80px;
  }
`

const ButtonS = styled(Button)`
  padding-left: 1.5em;
  padding-right: 1.5em;

  @media only screen and (max-height: 485px) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`

const CopyRight = styled.p`
  color: ${theme.colors.white};
  font-size: 12px;
  margin: 3vh 0 0;
`

const Background = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url(../../theme/intro-texture-blue.jpg) repeat 0 0 / 268px auto;
  pointer-events: none;

  ::after {
    content: '';
    position: absolute;
    top: 66.667%;
    right: 0;
    left: 0;
    bottom: 0;
    background: url(../../theme/intro-texture-yellow.jpg) repeat 0 0 / 268px auto;
  }
`

export const Intro = () => {
  const { isMobile, audio, screen, setScreen } = useContext(AppContext)
  const [playDryer, { stop: stopDryer }] = useSound(dryerSound, { loop: true })
  const [playScissors, { stop: stopScissors }] = useSound(scissorsSound, { loop: true })

  useEffect(() => {
    if (audio && screen === 'Intro') {
      playDryer()
      playScissors()
    } else {
      stopDryer()
      stopScissors()
    }
  }, [audio, screen, playDryer, playScissors, stopDryer, stopScissors])

  const startGame = async () => {
    if (isMobile) {
      try {
        await DeviceOrientationEvent.requestPermission()
      } catch (e) {
        console.error(e)
      }
    }
    setScreen('Game')
  }

  return (
    <ScreenS>
      <Content>
        <Logo />
        <Wrapper>
          {!isMobile && (
            <Help>
              <KeyboardS />
              <HelpText>
                Press left <br />
                to move left
              </HelpText>
            </Help>
          )}
          <SheepWrapper>
            <Sheep />
          </SheepWrapper>
          {!isMobile && (
            <Help>
              <KeyboardS style={{ transform: 'rotateY(180deg)' }} />
              <HelpText>
                Press right <br />
                to move right
              </HelpText>
            </Help>
          )}
        </Wrapper>
        <Footer>
          <ButtonS onClick={startGame}>Play</ButtonS>
          <CopyRight>&copy; Vitale Barberis Canonico 2020</CopyRight>
        </Footer>
      </Content>
      <Background />
    </ScreenS>
  )
}
