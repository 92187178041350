import { useEffect, useRef } from 'react'

const useWakeLock = () => {
  const wakeLock = useRef(null)

  useEffect(() => {
    ;(async () => {
      try {
        wakeLock.current = await navigator.wakeLock.request('screen')
      } catch (e) {}
    })()

    return () =>
      (async () => {
        if (!wakeLock.current) return
        try {
          await wakeLock.current.release()
          wakeLock.current = null
        } catch (e) {}
      })()
  }, [])

  return wakeLock.current
}

export default useWakeLock
