import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { styled } from 'linaria/react'
import useSound from 'use-sound'
import { Score } from 'components/Score'
import { ScoreSheep } from 'components/ScoreSheep/ScoreSheep'
import Bucket from 'components/Bucket/Bucket'
import Wool from 'components/Wool/Wool'
import { AppContext } from 'contexts/AppContext'
import useAnimationFrame from 'hooks/useAnimationFrame'
import useInterval from 'hooks/useInterval'
import useWakeLock from 'hooks/useWakeLock'
import scoreSound from 'sounds/flap.m4a'
import bigScoreSound from 'sounds/bigScore.m4a'
import overlaps from 'utils/overlaps'
import randomNumber from 'utils/randomNumber'
import theme from 'theme/theme'

const startWoolInterval = 3500

const Screen = styled.div`
  flex: 0 0 50%;
  height: 50%;
  padding-top: 300px;
  position: relative;
  background: url(../../theme/intro-texture-yellow.jpg) repeat-x 0 0 / 268px auto,
    ${props => (props.$day ? theme.colors.gameBackground1 : theme.colors.gameBackground2)};
`

const Content = styled.div`
  position: relative;
  height: 100%;
`

const ScoreS = styled(Score)`
  position: absolute;
  top: 4vh;
  right: 4vw;
  font-size: 20px;
  line-height: 1;
  color: ${props => (props.$day ? '#000000' : '#ffffff')};
`

const Background = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background: linear-gradient(to bottom, #fccd4900, #fccd49) no-repeat 0 0 / 100% 268px;
  height: 268px;
`

export const Game = ({ started }) => {
  useWakeLock()
  const { audio, score, setScore } = useContext(AppContext)
  const [playScore] = useSound(scoreSound, { soundEnabled: audio, volume: 5 })
  const [playBigScore] = useSound(bigScoreSound, { soundEnabled: audio })
  const [day, setDay] = useState(true)
  const [wools, setWools] = useState([])
  // Add 2500 to wait for enter animation
  const [woolInterval, setWoolInterval] = useState(startWoolInterval + 2500)
  const [showScoreSheep, setShowScoreSheep] = useState(false)

  const bucketRef = useRef(null)
  const woolRefs = useRef({})
  const woolCount = useRef(0)

  useInterval(() => {
    if (!started) return

    setWools(wools => [].concat(wools, [`w-${++woolCount.current}`]))
    const baseNewInterval = Math.max(startWoolInterval - score * 40, 400)
    const randomization = randomNumber(-(baseNewInterval / 6), baseNewInterval / 6)
    const newInterval = Math.max(baseNewInterval + randomization, 400)
    setWoolInterval(newInterval)
  }, woolInterval)

  // Check if wool is caught by bucket
  const loop = useCallback(() => {
    if (!started) return

    const woolToRemove = Object.keys(woolRefs.current).filter(id =>
      bucketRef.current
        ? overlaps(
            bucketRef.current.getElementsByClassName('collisionZone')[0],
            woolRefs.current[id]
          )
        : false
    )

    if (woolToRemove.length) {
      woolToRemove.forEach(id => delete woolRefs.current[id])
      setScore(score => score + woolToRemove.length)
      setWools(wools => wools.filter(wool => !woolToRemove.includes(wool)))
    }
  }, [started, setScore])
  useAnimationFrame(loop)

  useEffect(() => {
    if (score > 0) {
      if (score % 5 === 0) {
        playBigScore()
        setShowScoreSheep(true)
      }
      playScore()
    }
  }, [score, playBigScore, playScore])

  useEffect(() => {
    // 0-9 -> day
    // 10-19 -> night
    // 20-29 -> day...
    setDay(Math.floor((score / 10) % 10) % 2 === 0)
  }, [score])

  return (
    <Screen $day={day}>
      <Background />
      <Content>
        {started && (
          <>
            {showScoreSheep && <ScoreSheep destroy={() => setShowScoreSheep(false)} />}
            <ScoreS $day={day} />
            {wools.map(id => (
              <Wool key={id} ref={ref => (woolRefs.current[id] = ref)} />
            ))}
            <Bucket ref={bucketRef} />
          </>
        )}
      </Content>
    </Screen>
  )
}
