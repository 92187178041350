import { styled } from 'linaria/react'
import logo from 'theme/logo.png'

const LogoS = styled.img`
  width: 300px;
  z-index: 4;
  position: relative;
  object-fit: contain;

  @media only screen and (max-height: 605px) {
    max-height: 80px;
  }
`

export const Logo = () => <LogoS src={logo} alt="Vitale Barberis Canonico" />
