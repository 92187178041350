import { useEffect, useRef } from 'react'

/** To work correctly, callback MUST be a function returned by useCallback */
const useAnimationFrame = callback => {
  const frame = useRef()
  const init = useRef(performance.now())
  const last = useRef(performance.now())

  useEffect(() => {
    const animate = () => {
      const now = performance.now()
      const time = now - init.current
      const delta = now - last.current
      const cancel = callback({ time, delta })
      last.current = now
      if (cancel !== true) frame.current = requestAnimationFrame(animate)
    }
    frame.current = requestAnimationFrame(animate)
    return () => cancelAnimationFrame(frame.current)
  }, [callback])
}

export default useAnimationFrame
