import React, { useCallback, useEffect, useState } from 'react'
import { styled } from 'linaria/react'
import useEventListener from '@use-it/event-listener'
import useAnimationFrame from 'hooks/useAnimationFrame'
import { ReactComponent as BucketSvg } from './bucket.svg'
import pxToPercentage from 'utils/pxToPercentage'

const BucketS = styled.div`
  position: absolute;
  bottom: 0;
  width: 12vw;
  max-width: 232px;
  min-width: 90px;

  svg {
    display: block;
  }

  .collisionZone {
    position: absolute;
    width: 90%;
    height: 10%;
    position: absolute;
    left: 6%;
    bottom: 40%;
  }
`

const Bucket = React.forwardRef((_props, ref) => {
  const [moving, setMoving] = useState(false)
  const [leftDown, setLeftDown] = useState(false)
  const [rightDown, setRightDown] = useState(false)

  useEventListener('keydown', ({ code }) => {
    if (code === 'ArrowLeft') setLeftDown(true)
    else if (code === 'ArrowRight') setRightDown(true)
  })

  useEventListener('keyup', ({ code }) => {
    if (code === 'ArrowLeft') setLeftDown(false)
    else if (code === 'ArrowRight') setRightDown(false)
  })

  useEffect(() => {
    const width = ref.current.getBoundingClientRect().width
    ref.current.style.left = `${pxToPercentage((window.innerWidth - width) / 2)}%`
  }, [ref])

  useEffect(() => {
    if (leftDown ? rightDown : !rightDown) setMoving(false)
    else setMoving(leftDown ? -1 : 1)
  }, [leftDown, rightDown])

  useEventListener('keyup', () => setMoving(false))

  useEventListener('deviceorientation', ({ gamma }) => {
    // Moving right
    if (gamma > 15) setMoving(0.5)
    // Moving left
    else if (gamma < -15) setMoving(-0.5)
    else setMoving(false)
  })

  const loop = useCallback(() => {
    if (!moving) return
    const prevLeft = parseInt(window.getComputedStyle(ref.current).left)
    const hWidth = ref.current.getBoundingClientRect().width / 2
    let left = prevLeft + moving * 10
    if (left < -hWidth) left = -hWidth
    if (left > window.innerWidth - hWidth) left = window.innerWidth - hWidth
    ref.current.style.left = `${pxToPercentage(left)}%`
  }, [moving, ref])
  useAnimationFrame(loop)

  return (
    <BucketS
      ref={ref}
      bucketRef={ref}
      style={{
        transform: `rotateZ(${moving ? moving * 4 : 0}deg)`,
        bottom: moving ? '-0.75vw' : 0,
      }}
    >
      <BucketSvg />
      <div className="collisionZone" />
    </BucketS>
  )
})

export default Bucket
