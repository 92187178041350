import { styled } from 'linaria/react'

export const Screen = styled.div`
  box-sizing: border-box;
  position: relative;
  padding: 2vh 4vw;

  @media only screen and (min-height: 701px) {
    padding-top: 4vh;
    padding-bottom: 4vh;
  }
`
