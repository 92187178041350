import { useContext } from 'react'
import { styled } from 'linaria/react'
import { AppContext } from 'contexts/AppContext'
import { Button } from 'components/Button'
import { GameOverBanner } from 'components/GameOverBanner/GameOverBanner'
import { Icon } from 'components/Icon'
import { Logo } from 'components/Logo'
import { Screen } from 'components/Screen'
import { ReactComponent as Discover } from 'theme/icons/discover.svg'
import { ReactComponent as Restart } from 'theme/icons/restart.svg'
import theme from 'theme/theme'

const ScreenS = styled(Screen)`
  height: 100%;
  background-color: ${theme.colors.gameOverBackground};
`

const Content = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-between;
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 2vh;

  @media only screen and (min-height: 841px) {
    margin-top: 3em;
  }
`

const HelpText = styled.div`
  text-align: center;
  color: ${theme.colors.white};
  margin-top: 1vh;
`

export const GameOver = () => {
  const { setScreen, setScore } = useContext(AppContext)
  const onRestart = () => {
    setScore(0)
    setScreen('Game')
  }

  return (
    <ScreenS>
      <Content>
        <Logo />
        <GameOverBanner />
        <ButtonsWrapper>
          <div style={{ marginRight: 80 }}>
            <Button onClick={onRestart}>
              <Icon icon={<Restart />} />
            </Button>
            <HelpText>Play Again</HelpText>
          </div>
          <div>
            <Button
              onClick={() =>
                (window.location =
                  'https://vitalebarberiscanonico.com/fabrics/21-micron/')
              }
              style={{ padding: 8, width: 66, height: 66, boxSizing: 'border-box' }}
            >
              <Discover />
            </Button>
            <HelpText>Discover</HelpText>
          </div>
        </ButtonsWrapper>
      </Content>
    </ScreenS>
  )
}
